import React from "react";

import { graphql } from "gatsby";

import { FounderMessage } from "../../components/founder-message";
import { ContentLayout } from "../../layouts/content-layout";
import { DefaultLayout } from "../../layouts/default-layout";


// CSS
import "./about-us.scss";

const AboutUsTemplate = ({ data, pageContext }) => {
    const pageDetails = {
        title: pageContext.title
    };
    const siteName = (data && data.site && data.site.siteMetadata && data.site.siteMetadata.siteName) || "Product Jack";
    const aboutUs = (data && data.site && data.site.siteMetadata && data.site.siteMetadata.aboutUs) || [];

    return (
        <DefaultLayout pageDetails={pageDetails}>
            <ContentLayout title={pageContext.title} margin={false}>
                <FounderMessage />
            </ContentLayout>
        </DefaultLayout>
    );
};

export const query = graphql`
    query AboutUsQuery {
        site {
            siteMetadata {
                layout
                siteName
                aboutUs
            }
        }
    }
`;

export default AboutUsTemplate;